<template>
  <ckeditor :editor="editor" v-model="localStatuteData" :config="editorConfig" @input="onEditorInput"></ckeditor>
</template>
<script>
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic'

import { Alignment } from '@ckeditor/ckeditor5-alignment'
import { Autoformat } from '@ckeditor/ckeditor5-autoformat'
import { Bold, Italic } from '@ckeditor/ckeditor5-basic-styles'
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote'
import { CloudServices } from '@ckeditor/ckeditor5-cloud-services'
import { Essentials } from '@ckeditor/ckeditor5-essentials'
import { Heading } from '@ckeditor/ckeditor5-heading'
import { Indent } from '@ckeditor/ckeditor5-indent'
import { Link } from '@ckeditor/ckeditor5-link'
import { List } from '@ckeditor/ckeditor5-list'
import { MediaEmbed } from '@ckeditor/ckeditor5-media-embed'
import { Paragraph } from '@ckeditor/ckeditor5-paragraph'
import { PasteFromOffice } from '@ckeditor/ckeditor5-paste-from-office'
import { Table, TableToolbar } from '@ckeditor/ckeditor5-table'
import { TextTransformation } from '@ckeditor/ckeditor5-typing'
import { Undo } from '@ckeditor/ckeditor5-undo'
import { Font } from '@ckeditor/ckeditor5-font'
import { SourceEditing } from '@ckeditor/ckeditor5-source-editing'
export default {
  props: ['statuteData'],
  data () {
    return {
      localStatuteData: this.statuteData,
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          Alignment,
          Autoformat,
          BlockQuote,
          Bold,
          CloudServices,
          Essentials,
          Heading,
          Indent,
          Italic,
          Link,
          List,
          MediaEmbed,
          Paragraph,
          PasteFromOffice,
          Table,
          TableToolbar,
          TextTransformation,
          Undo,
          Font,
          SourceEditing
        ],
        toolbar: {
          items: [
            'undo',
            'redo',
            '|',
            'heading',
            '|',
            'bold',
            'italic',
            'fontColor',
            'fontSize',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'outdent',
            'indent',
            '|',
            'blockQuote',
            'insertTable',
            'mediaEmbed',
            'alignment',
            '|',
            'sourceEditing'
          ]
        },
        language: 'en',
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells'
          ]
        }
      }
    }
  },
  watch: {
    statuteData (newVal) {
      this.localStatuteData = newVal
    }
  },
  methods: {
    onEditorInput () {
      this.$emit('statueChange', this.localStatuteData)
    }
  }
}
</script>
<style>
.ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
  border: none;
}

.ck.ck-editor__main>.ck-editor__editable {
  border: none;
  height: 600px;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  border: none !important;
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  border: none !important;
  box-shadow: none;
}

.ck-rounded-corners .ck.ck-toolbar,
.ck.ck-toolbar.ck-rounded-corners {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.ck-source-editing-area {
  background-color: #fff;
  height: 600px;
}

.ck-source-editing-area textarea {
  border: none !important;
}
</style>
