<template>
    <div>
        <AppBar :title="id ? $t('statute.edit') : $t('statute.add')" />
        <v-card flat class="rounded-xl mb-4">
            <v-card-text>
                <v-form ref="addStatute" v-model="valid">
                    <v-row>
                        <v-col>
                            <v-text-field v-model="name" :label="$t('users.name')" :rules="required"></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <Statute @statueChange="statuteChange" :statuteData="statuteContent"/>
        <v-card flat class="rounded-xl mt-4">
            <v-card-actions>
                <v-row justify="space-between">
                  <v-col cols="auto">
                    <v-btn depressed color="secondary" @click="save" :disabled="loading" :loading="loading" rounded>{{ $t('buttons.save') }}</v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-row>
                      <v-col v-if="id" cols="auto">
                        <v-btn depressed color="error" @click="remove" :disabled="loading" rounded>{{ $t('statute.delete') }}</v-btn>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn depressed @click="close" :disabled="loading" block rounded>{{ $t('buttons.close') }}</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        <Confirm ref="confirm"></Confirm>
    </div>
</template>

<script>
import Service from '@/services'
import Statute from './Statute.vue'
import Confirm from '../../components/dialogs/Confirm.vue'
export default {
  name: 'AddEditStatute',
  components: {
    Statute,
    Confirm
  },
  data () {
    return {
      id: this.$route.params.id,
      loading: false,
      valid: true,
      name: '',
      statuteContent: '',
      required: [(v) => !!v || this.$t('validation.fieldRequired')],
      countryCode: localStorage.getItem('tenantValue')
    }
  },
  async created () {
    if (this.$route.params.id) {
      await this.getStatute()
    }
  },
  methods: {
    async getStatute () {
      this.loading = true

      try {
        const { data } = await Service.get(`Regulations/${this.$route.params.id}/Management`)
        this.name = data.name
        this.statuteContent = data.content
      } catch (error) {}

      this.loading = false
    },
    statuteChange (statute) {
      this.statuteContent = statute
    },
    async save () {
      this.loading = true
      this.$refs.addStatute.validate()
      if (!this.valid) {
        this.loading = false
        return
      }
      try {
        if (this.id) {
          await Service.put(`/Regulations/${this.$route.params.id}`, {
            name: this.name,
            content: this.statuteContent,
            countryCode: this.countryCode
          })
        } else {
          await Service.post('/Regulations', {
            name: this.name,
            content: this.statuteContent,
            countryCode: this.countryCode
          })
        }

        this.$refs.addStatute.reset()
        this.$router.push({ path: '/statute' })
      } catch (error) {}
      this.loading = false
    },
    async remove () {
      this.loading = true

      if (await this.$refs.confirm.open(this.$t('statute.removeStatute'), `${this.$t('statute.removeStatuteConfirm')} ${this.name}?`)) {
        try {
          await Service.delete('Regulations', this.id)

          this.$refs.addStatute.reset()
          this.$router.push({ path: '/statute' })
        } catch (error) {}
      }
      this.loading = false
    },
    close () {
      this.$router.push({ path: '/statute' })
    }
  }
}
</script>
